import React from "react"
import { Link } from "gatsby"

const GlobalOfferTiles = () => {
  return (
    <div className="row">
      <div className="col-sm-6">
        <Link
          to="/oferta/podologia/"
          className="global-offer__tile global-offer__tile-1"
        >
          <h4>Podologia</h4>
          <p>
            Leczenie <br />i profilaktyka <br />
            dolegliwości <br />
            stóp.
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/fizjoterapia/"
          className="global-offer__tile global-offer__tile-2"
        >
          <h4>Fizjoterapia</h4>
          <p>
            Utrzymanie, <br />
            przywracanie <br />i rozwijanie <br />
            sprawności <br />
            ruchowej.
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/chirurgia-palca/"
          className="global-offer__tile global-offer__tile-3"
        >
          <h4>Chirurgia palca</h4>
          <p>
            Leczenie <br /> i profilaktyka <br />
            dolegliwości <br />
            paznokci
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/kriochirurgia/"
          className="global-offer__tile global-offer__tile-4"
        >
          <h4>Kriochirurgia - usuwaniebrodawek i włókniaków</h4>
          <p>
            Usuwanie <br />
            brodawek i <br />
            włókniaków
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/laseroterapia/"
          className="global-offer__tile global-offer__tile-5"
        >
          <h4>Laseroterapia</h4>
          <p>
            Usuwanie zmian <br />
            skórnych <br /> (blizny, brodawki, <br />
            hiperkeratozy, <br />
            odciski)
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/psychologia/"
          className="global-offer__tile global-offer__tile-6"
        >
          <h4>Psychologia</h4>
          <p>
            Konsultacje <br />
            psychologiczne, <br />
            terapia <br />
            indywidualna <br />i grupowa
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/dietetyka/"
          className="global-offer__tile global-offer__tile-7"
        >
          <h4>Dietetyka</h4>
          <p>
            Tworzenie i<br />
            dostosowanie
            <br />
            indywidualnego
            <br />
            planu
            <br />
            żywieniowego
          </p>
        </Link>
      </div>
      <div className="col-sm-6">
        <Link
          to="/oferta/pielegniarstwo/"
          className="global-offer__tile global-offer__tile-8"
        >
          <h4>Pielęgniarstwo</h4>
          <p>
            Monitorowanie
            <br />
            stanu zdrowia
            <br />
            pacjentów,
            <br />
            podawanie
            <br />
            leków.
          </p>
        </Link>
      </div>
    </div>
  )
}

export default GlobalOfferTiles
